.react-datepicker__header {
  text-align: center;
  background-color: #3e82cf;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;

}

.react-datepicker__today-button {
  background: #3e82cf;
  border-top: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #a66;
  color: #000;
  border: 0px;
  border-radius: 0;
  display: inline-block;
  position: relative;
}